// material
import { Stack, Button, Divider, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
// component
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useState } from 'react';

import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function AuthSocial({ google_onsuccess, google_onerror }) {

  const [isloading, setIsloading] = useState(false)

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setIsloading(false);
      await google_onsuccess(codeResponse.code)
    },
    flow: 'auth-code',
    prompt:'none'
  });

  return (
    <>
      <Stack direction="row" spacing={2}>
        {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
        </Button> */}
        <Button
          disabled={isloading}
          onClick={() => {
            setIsloading(true);
            login()
          }}
          variant="outlined" startIcon={<Iconify icon="devicon:google" />
          }>
          Login with Google
        </Button>
      </Stack>



      {/* <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider> */}
    </>
  );
}
